<template>
  <e-commerce-checkout-step-cart
    :checkout-details="checkoutDetails"
    :is-loading="isLoading"
    :readonly="true"
  />
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import store from '@/store'
import purchaseStoreModule from '@/views/models/purchases/purchaseStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import { usePurchaseCart } from '@/views/models/purchases/usePurchaseCart'
import { toast } from '@core/utils/utils'
import Router from '@/router'
import ECommerceCheckoutStepCart from './PurchaseCheckoutStepCart.vue'

export default {
  components: {
    // 3rd Party

    // SFC
    ECommerceCheckoutStepCart,
  },
  mounted() {
    if (this.refFormWizard) {
      this.refFormWizard.activateAll()
    }
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup() {
    const STORE_MODULE_NAME = 'purchases'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, purchaseStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, purchaseStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    const isLoading = ref(false)
    const checkoutDetails = ref({
      id: Router.currentRoute.params.id,
      summary: {
        subtotal: {
          currency: 'USD',
          amount: 0,
        },
        discount: {
          currency: 'USD',
          amount: 0,
        },
        total: {
          currency: 'USD',
          amount: 0,
        },
      },
      payer: {},
      payment_methods: {},
      coupon_code: null,
      payment_method_code: null,
    })

    const refs = ref({})
    const { fetch } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      checkoutDetails,
      () => {},
      null,
      null,
    )

    isLoading.value = true
    fetch().finally(() => {
      isLoading.value = false
    })

    const { checkoutPurchase, updateProductQty, removeProductFromCart } = usePurchaseCart()

    const onPaymentSubmit = payload => {
      console.log('PAYLOAD', payload)

      checkoutPurchase(payload.code, payload.payload).then(response => {
        if (typeof (payload.callback) === 'function') {
          payload.callback(true)
        }
        console.log('CONFIRMATION', response)
        Router.push({ name: 'purchases.confirmation', params: { id: response.id } })
      }).catch(e => {
        payload.callback(false)
        toast('danger', 'Error', e.response?.data?.message || e.message)
      }).finally(() => {
        payload.callback(null)
      })
    }

    const onClickCartItemQtyUpdate = payload => {
      isLoading.value = true

      updateProductQty(payload.id, payload.qty).then(response => {
        checkoutDetails.value = response
      }).finally(() => {
        isLoading.value = false
      })
    }
    const onClickCartItemRemove = payload => {
      isLoading.value = true
      removeProductFromCart(payload).then(response => {
        checkoutDetails.value = response
      }).finally(() => {
        isLoading.value = false
      })
    }

    return {
      refs,
      refFormWizard,
      isLoading,
      formWizardNextStep,
      onPaymentSubmit,
      onClickCartItemQtyUpdate,
      onClickCartItemRemove,

      // Dummy Details
      checkoutDetails,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard';
@import '~@core/scss/base/pages/app-ecommerce';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
