<template>
  <b-card>

    <!--    <label class="section-label mb-1">{{ $t('modules.purchases._create.summary') }}</label>-->
    <div class="price-details    list-view product-checkout mt-0">
      <ul class="list-unstyled">
        <li class="price-detail">
          <div class="detail-title">
            {{ $t('Status') }}
          </div>
          <div class="detail-amt">
            <b-badge
              v-t="`modules.purchases.status.${checkoutDetails.status}`"
              pill
              :variant="`light-${resolvePurchaseStatusVariant(checkoutDetails.status)}`"
              class="text-capitalize"
            >
              {{ checkoutDetails.status_description || checkoutDetails.status }}
            </b-badge>
          </div>
        </li>
      </ul>
      <ul class="list-unstyled">
        <li class="price-detail">
          <div class="detail-title">
            {{ $t('inputs.payment_method') }}
          </div>
          <div class="detail-amt">
            {{ checkoutDetails.payment_method ? checkoutDetails.payment_method.name : '-' }}
          </div>
        </li>
        <li class="price-detail pt-1">
          <div class="detail-title">
            {{ $t('Payment Date') }}
          </div>
          <b-badge
            v-if="checkoutDetails.paid_at"
            pill
            :variant="`light-success`"
            class="text-capitalize"
          >
            {{ formatDatetime(checkoutDetails.paid_at) }}
          </b-badge>
          <b-badge
            v-else
            v-t="`modules.purchases.payment_status.not_paid`"
            pill
            :variant="`light-secondary`"
            class="text-capitalize"
          />
        </li>
        <!--            <li class="price-detail">-->
        <!--              <div class="detail-title">-->
        <!--                Payment Fees-->
        <!--              </div>-->
        <!--              <div class="detail-amt discount-amt text-success">-->
        <!--                Free-->
        <!--              </div>-->
        <!--            </li>-->
      </ul>
    </div>

  </b-card>
</template>

<script>
import {
  BBadge,
  BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import useModelList from '@/views/models/common/useModelList'
import ECommerceCheckoutStepCartProducts from './PurchaseCheckoutStepCartProducts.vue'
import { formatDatetime } from '../../../../@core/utils/filter'

export default {
  components: {
    BBadge,
    // BSV
    BButton,
    BCard,
  },
  props: {
    checkoutDetails: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: { formatDatetime },
  emits: ['update-qty', 'remove-product'],
  setup(props, { emit }) {
    const {
      resolvePurchaseStatusVariant,
    } = useModelList('purchases', [])

    return {
      resolvePurchaseStatusVariant,
    }
  },
}
</script>
