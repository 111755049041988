<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    variant="secondary"
    opacity="0.75"
  >
    <purchase-details :checkout-details="checkoutDetails" />
    <div class="list-view product-checkout mt-0">
      <e-commerce-checkout-step-cart-products
        v-if="!isLoading"
        :items="checkoutDetails.items"
        :readonly="readonly"
        @update-qty="payload => $emit('update-qty', payload)"
        @remove-product="payload => $emit('remove-product', payload)"
      />

      <!-- Checkout Options -->
      <div class="checkout-options">
        <b-card>

          <label class="section-label mb-1">{{ $t('modules.purchases._create.summary') }}</label>
          <!--        <b-input-group class="input-group-merge coupons">-->
          <!--          <b-form-input placeholder="Coupons" />-->
          <!--          <b-input-group-append is-text>-->
          <!--            <span-->
          <!--              id="input-coupons"-->
          <!--              class="input-group-text text-primary cursor-pointer"-->
          <!--            >Apply</span>-->
          <!--          </b-input-group-append>-->
          <!--        </b-input-group>-->
          <!--        <hr>-->
          <div class="price-details">
            <!--          <h6 class="price-title">-->
            <!--            Price Details-->
            <!--          </h6>-->
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title">
                  {{ $t('modules.purchases._create.subtotal') }}
                </div>
                <div class="detail-amt">
                  <template v-if="checkoutDetails.summary.subtotal.currency === 'USD'">
                    {{ checkoutDetails.summary.subtotal.string }}
                  </template>
                  <money
                    v-else
                    :value="checkoutDetails.summary.subtotal.amount"
                    :currency="checkoutDetails.summary.subtotal.currency"
                  />
                </div>
              </li>
              <li class="price-detail">
                <div class="detail-title">
                  {{ $t('modules.purchases._create.discount') }}
                </div>
                <div
                  v-if="checkoutDetails.summary.discount.amount"
                  class="detail-amt discount-amt text-success"
                >
                  <template v-if="checkoutDetails.summary.discount.currency === 'USD'">
                    {{ checkoutDetails.summary.discount.string }}
                  </template>
                  <money
                    v-else
                    :value="checkoutDetails.summary.discount.amount"
                    :currency="checkoutDetails.summary.discount.currency"
                  />
                </div>
                <div
                  v-else
                  class="detail-amt discount-amt"
                >-</div>
              </li>
              <!--            <li class="price-detail">-->
              <!--              <div class="detail-title">-->
              <!--                Payment Fees-->
              <!--              </div>-->
              <!--              <div class="detail-amt discount-amt text-success">-->
              <!--                Free-->
              <!--              </div>-->
              <!--            </li>-->
            </ul>
            <hr>
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title detail-total">
                  {{ $t('modules.purchases._create.total') }}
                  <small> ({{ $t('modules.purchases._create.tax_inclusive') }})</small>
                </div>
                <div class="detail-amt font-weight-bolder">
                  <template v-if="checkoutDetails.summary.total.currency === 'USD'">
                    {{ checkoutDetails.summary.total.string }}
                  </template>
                  <money
                    v-else
                    :value="checkoutDetails.summary.total.amount"
                    :currency="checkoutDetails.summary.total.currency"
                  />
                </div>
              </li>
            </ul>
            <b-button
              v-if="!readonly"
              variant="primary"
              block
              @click="$emit('next-step')"
            >
              {{ $t('modules.purchases.actions.continue') }}
            </b-button>
          </div>

        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import PurchaseDetails from '@/views/models/purchases/view/PurchaseDetails.vue'
import ECommerceCheckoutStepCartProducts from './PurchaseCheckoutStepCartProducts.vue'

export default {
  components: {
    PurchaseDetails,
    BOverlay,
    // BSV
    BButton,
    BCard,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  emits: ['update-qty', 'remove-product'],
  props: {
    checkoutDetails: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
